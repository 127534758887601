html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
  /* Give app full page to work with */
  height: 100vh;
}

/* #page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page 
  overflow: auto;
} */

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  margin-bottom: 10px;
  color: #faf5f5;
  transition: color 0.2s;
  overflow: auto;
}

/* Change color on hover */
.bm-item:hover {
  color: rgb(4, 201, 250);
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 26px;
  height:17px;
  left: 10px;
  top: 2px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fdfdfd;
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #f6f6f7;
}

/* General sidebar styles */
/* @media screen and (max-width : 1300px) {} */

.bm-menu {
  position: relative;
  background: #152938;
  padding: 1.5em 0.50em 0;
  font-size: 1.0em;
  min-height: 350px;
  overflow: auto;
}

.menuSeprartor{
  border-bottom: 0.1px solid rgb(116, 116, 116);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #f5f6fa;
}

/* Wrapper for item list */
.bm-item-list {
  color: #f8f8f7;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.username-logo {
  position: relative;
  width: 36px;
  height: 25px;
  left: 930px;
  top: 5px;  
  color:white;
  font-family: 'Gill Sans';
  float:left;
}

#page-wrap { 
  width: 600px; 
  margin: 15px auto; 
  position: relative; 
} 

.menuSpacing{
   margin-bottom: 45px;

}

.menuTopSpacing{
  margin-top: 85px;
}

.greyOut{
  color:grey;
}
